body {
  background-color: #1f2833;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

.home_container {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form,
.shortened_url_container {
  display: flex;
  flex-direction: column;
}

.url_form_container input[type="text"],
.short_url_text {
  border: none;
  outline: none;
  border-bottom: 1px solid #45a29e;
  font-size: 1.3em;
  background-color: #1f2833;
  color: #66fcf1;
  width: 200px;
}

.url_form_container input[type="text"]::placeholder {
  color: red;
}

.url_form_container input[type="submit"],
button {
  background-color: #1f2833;
  box-shadow: none;
  outline: none;
  border: 1px solid #66fcf1;
  color: white;
  height: 40px;
  margin-top: 12px;
  border-radius: 2px;
  letter-spacing: 2px;
  cursor: pointer;
}

.url_form_container input[type="submit"]:active,
button {
}

.url_form_container input[type="submit"]:hover,
button:hover {
  transform: scale(1.05);
}

.error {
  margin: 0;
  color: rgb(255, 0, 0);
}

.loader_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* loader taken from: https://www.w3schools.com/howto/howto_css_placeholder.asp */

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #66fcf1; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.not_found_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not_found_container > h3 {
  color: white;
}

.not_found_container > a {
  color: white;
}

.not_found_container > a:hover {
  text-decoration: underline;
}

.stats_container {
  margin-top: 15%;
}

.stats_container ul {
  padding: 0;
}

.stats_container li {
  list-style: none;
  color: white;
}

.stats_container ul > *:not(:last-child) {
  margin-bottom: 6px;
}

.stats_container h3 {
  color: white;
}

.stats_container li > a {
  color: inherit;
}

.short_url_text:visited {
  color: #66fcf1;
}
